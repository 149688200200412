import React, {useContext, useEffect, useState} from 'react';
import '../css/Modal.css';
import ConfirmContext from "./context/ConfirmContext";
import PaymentContext from "./context/PaymentContext";

function Payment() {
  const {payment,setPayment} = useContext(PaymentContext);

  useEffect(() => {
    if(payment)
    {
      if(payment.show) showModal();
    }
  },[payment]);



  function showModal(){

    document.querySelector("body").classList.add("modal-open");
    document.querySelector(".payment-modal").classList.add("show");
    document.querySelector(".modal-backdrop").classList.add("show");

  }

  function hideModal(){

    document.querySelector("body").classList.remove("modal-open");
    document.querySelector(".payment-modal").classList.remove("show");
    document.querySelector(".modal-backdrop").classList.remove("show");

  }

  function confirmModal(){
    if(payment.onConfirm) payment.onConfirm();
    hideModal();
  }

  function cancelModal(){
    if(payment.onUnconfirm) payment.onUnconfirm();
    hideModal();
  }


  return (

    <div className="modal fade payment-modal" id="default-modal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancelModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="form-element">
                <label htmlFor="full-name">Card Holder Name</label>
                <input type="text" className={"form-control"} id="first_name" placeholder="Full Name"   />

              </div>
              <div className="form-element">
                <label htmlFor="full-name">Card Number</label>
                <input type="text" className={"form-control"} id="first_name" placeholder="XXXX XXXX XXXX XXXX"   />

              </div>

              <div className="form-element">
                <label htmlFor="full-name">Card Last Valid Date</label>
                <input type="text" className={"form-control"} id="first_name" placeholder="MM/YY"   />

              </div>

              <div className="form-element">
                <label htmlFor="full-name">Card CVC Number</label>
                <input type="text" className={"form-control"} id="first_name" placeholder="XXX"   />

              </div>



            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="default-button secondary" data-dismiss="modal" onClick={cancelModal}>CANCEL</button>
            <button type="button" className="default-button" onClick={confirmModal}>MAKE PAYMENT</button>
          </div>
        </div>
      </div>
    </div>

  );
  
}

export default Payment;